import React from "react"
import Layout from "../components/layout";


export default class NotFoundPage extends React.Component {

    render() {
        return (
          <Layout>
            <div>Pagina no existe!</div>
          </Layout>
        )
    }
}
